<template>
  <b-sidebar
    id="add-new-car-active"
    :visible="isAddNewCarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    :width="sidebarMinWidth"
    shadow
    backdrop
    no-header
    right
    @hidden="resetCarData"
    @abort="resetCarData"
    @change="(val) => $emit('update:is-add-new-car-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5
          class="mb-0"
        >
          Neues Fahrzeug
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <div
        :style="`padding-left:${bodyPadding}px; padding-right:${bodyPadding}px;`"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="refNewCarFormObserver"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <h6>Fahrzeug</h6>
            <hr>
            <b-form-group
              label="Marke und Modell"
              label-for="title"
            >
              <validation-provider
                #default="{ errors }"
                name="Marke und Modell"
                rules=""
              >
                <b-form-input
                  id="title"
                  v-model="newCarData.carTitle"
                  trim
                  placeholder=""
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Fahrzeugidentifikationsnummer*"
              label-for="vin"
            >
              <validation-provider
                #default="{ errors }"
                name="Fahrzeugidentifikationsnummer"
                rules="required|length:17|alpha-num"
              >
                <b-form-input
                  id="vin"
                  v-model="newCarData.vin"
                  trim
                  placeholder=""
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-tooltip
              target="Steckertyp-tooltip"
              title="
                      Typ 1 ist bei amerikanischen Fahrzeugen üblich, er ist ein Einphasenstecker und kann mit einer Geschwindigkeit von bis zu 7,4 kW aufladen.
                      Typ 2 ist Standard für europäische und asiatische Fahrzeuge ab 2018. Er ist ein dreiphasiger Stecker und kann mit einer Leistung von bis zu 43 kW aufladen.
                      CCS ist eine Version des Typs 2 mit zwei zusätzlichen Stromkontakten. Er ermöglicht ein sehr schnelles Laden.
                      CHAdeMO ist in asiatischen Autos zu finden und ermöglicht sowohl hohe Ladekapazitäten als auch bidirektionales Laden.
                    "
            />
            <b-form-group>
              <label
                for="Steckertyp"
                class="d-block"
              >
                Steckertyp
                <feather-icon
                  id="Steckertyp-tooltip"
                  icon="InfoIcon"
                  size="15"
                />
              </label>
              <b-form-select
                v-model="newCarData.connectorType"
                :options="connectorTypes"
              />
            </b-form-group>

            <b-form-group
              label="Nutzung"
              label-for="useType"
            >
              <b-form-select
                v-model="newCarData.useType"
                :options="useTypes"
              />
            </b-form-group>
            <b-form-group
              label="Ladeadresse, für die überwiegende Ladung, nicht-öffentlich zugänglich*"
              label-for="address"
            >
              <validation-provider
                #default="{ errors }"
                name="Ladeadresse"
                rules="required"
              >
                <b-form-select
                  v-model="newCarData.address"
                  :options="addressList"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div
              v-if="newCarData.address==-1"
            >
              <b-form-group
                label="Strasse + Nummer*"
                label-for="addressStreet"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Strasse + Nummer"
                  rules="required"
                >
                  <b-form-input
                    id="addressStreet"
                    v-model="newCarData.addressStreet"
                    trim
                    placeholder=""
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-warning">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Postleitzahl*"
                label-for="addressZip"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Postleitzahl"
                  rules="required"
                >
                  <b-form-input
                    id="addressZip"
                    v-model="newCarData.addressZip"
                    trim
                    placeholder=""
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-warning">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="Ort*"
                label-for="addressCity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Ort"
                  rules="required"
                >
                  <b-form-input
                    id="addressCity"
                    v-model="newCarData.addressCity"
                    trim
                    placeholder=""
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-warning">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <!--
            <b-tooltip
              target="powerUsed-tooltip"
              title="Wenn am Ladepunkt gemessen und nachvollziehbar überprüfbar aufgezeichnet wird, muss gemäß KVO §11 die jährliche Strommenge (in kWh) angegeben werden und mit Jahresende die Angabe auf den gemessenen Wert korrigiert werden. Es kann sein, dass sich dadurch auch die Höhe des Bonus ändert."
            />
            -->
            <b-form-group>
              <label
                for="Ladestelleninfo"
                class="d-block"
              >
                Infofeld: Bonus / Rabattcode / Kundennummer / etc.
                <feather-icon
                  id="extraCharhingInfo-tooltip"
                  icon="InfoIcon"
                  size="15"
                />
              </label>
              <b-form-input
                id="extraCharhingInfo"
                v-model="newCarData.extraCharhingInfo"
                trim
                placeholder=""
              />
            </b-form-group>
            <!-- First Name -->
            <validation-provider
              #default="validationContext"
              name="Startzeitpunkt"
              rules="required|compareDates:@Endzeitpunkt"
            >
              <label
                for="dateFrom"
                class="d-block"
              >
                Startzeitpunkt*
              </label>
              <b-alert
                variant="secondary"
                :show="true"
              >
                <div class="alert-body">
                  <span style="font-size:13px;">
                    Startzeitpunkt, das ist der Zeitpunkt an dem Sie das Fahrzeug erstmalig an oben genannter Ladeadresse laden. Dieser Zeitpunkt kann nicht vor dem Zulassungsdatum liegen.
                  </span>
                </div>
              </b-alert>
              <b-form-group>
                <b-form-datepicker
                  v-model="newCarData.dateFrom"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="de"
                  :min="minDate"
                  :max="maxDate"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
                <small class="text-warning">{{ validationContext.errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <b-tooltip
              target="dateFrom-tooltip"
              title="Datum kann nicht vor dem Zulassungsdatum liegen."
            />

            <b-tooltip
              target="extraCharhingInfo-tooltip"
              title="Falls für Ihre interne Verwaltung von Interesse, zB bei mehreren Ladestellen an einer Adresse."
            />

            <!-- Last Name -->
            <validation-provider
              #default="validationContext"
              name="Endzeitpunkt"
              rules="required"
            >
              <b-form-group
                label="Endzeitpunkt*"
                label-for="lastName"
              >
                <b-form-datepicker
                  v-model="newCarData.dateTo"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  locale="de"
                  :min="minDate"
                  :max="maxDate"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-tooltip
              target="Zulassungsschein-tooltip"
              title="Foto Hochformat, bei Papier mit der Stampiglie der Zulassungsstelle."
            />
            <b-form-group>
              <label
                for="Zulassungsschein"
                class="d-block"
              >
                Zulassungsschein Vorderseite*
                <feather-icon
                  id="Zulassungsschein-tooltip"
                  icon="InfoIcon"
                  size="15"
                />
              </label>
              <!-- needs to be deleted! allow-multiple="false" -->
              <!-- https://github.com/pqina/vue-filepond/issues/33 -->
              <file-pond
                ref="pondA"
                name="fileUpload"
                label-idle="Wählen Sie eine Datei oder ziehen Sie es ins Feld Unterstützte. Dateiformate: JPG, PNG - Max Größe: 20MB"
                allow-replace="true"
                allow-revert="true"
                accepted-file-types="image/jpeg, image/png"
                :server="{
                  url: apiUrl,
                  process: {
                    url: `/file-repository/upload/${tempFormUUID}/permit_side_a/${authUser.id}`,
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    },
                  },
                  revert: {
                    url: `/file-repository/revert/${tempFormUUID}/permit_side_a/${authUser.id}`,
                    method: 'DELETE',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    },
                  },
                }"
                allow-file-encode="true"
                allow-file-rename="true"
                allow-image-resize="true"
                image-resize-target-width="1280"
                image-resize-target-height="720"
                image-resize-mode="cover"
                :file-rename-function="fileRenameFunction"
                @processfile="processFile"
                @removefile="removeFile"
              />
            </b-form-group>
            <b-tooltip
              target="Zulassungsschein-ruckseit-tooltip"
              title="Foto Hochformat, bei Papier die gesamte Datenseite der Langfassung des Zulassungsscheins mit Antriebsart."
            />
            <b-form-group>
              <label
                for="Zulassungsschein"
                class="d-block"
              >
                Zulassungsschein Rückseite*
                <feather-icon
                  id="Zulassungsschein-ruckseit-tooltip"
                  icon="InfoIcon"
                  size="15"
                />
              </label>

              <!-- needs to be deleted! allow-multiple="false" -->
              <!-- https://github.com/pqina/vue-filepond/issues/33 -->
              <file-pond
                ref="pondB"
                name="fileUpload"
                label-idle="Wählen Sie eine Datei oder ziehen Sie es ins Feld. Unterstützte Dateiformate: JPG, PNG - Max Größe: 20MB"
                allow-replace="true"
                allow-revert="true"
                accepted-file-types="image/jpeg, image/png"
                :server="{
                  url: apiUrl,
                  process: {
                    url: `/file-repository/upload/${tempFormUUID}/permit_side_b/${authUser.id}`,
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    },
                  },
                  revert: {
                    url: `/file-repository/revert/${tempFormUUID}/permit_side_b/${authUser.id}`,
                    method: 'DELETE',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    },
                  },
                }"
                allow-file-encode="true"
                allow-file-rename="true"
                allow-image-resize="true"
                image-resize-target-width="1280"
                image-resize-target-height="720"
                image-resize-mode="cover"
                :file-rename-function="fileRenameFunction"
                @processfile="processFile"
                @removefile="removeFile"
              />
            </b-form-group>
            <br>
            <h6>Ladestelle – eine der untenstehenden Bestätigungen auswählen</h6>
            <hr>
            <!--
            <b-form-checkbox
              v-model="newCarData.flatRate"
              @change="processFile"
              class="custom-control-primary"
            >
              <small>Ich bestätige hiermit, dass <b>eine nachweisliche Messung nicht möglich</b> ist, da der Nachweis einer infrastrukturseitigen Messung auf Ladepunktebene mittels MID konformer Stromzähler (EU-Messgeräterichtlinie, Measurement Instruments Directive, RL 2014/32/EU) oder gleichwertigen bzw. besseren Zählers (z.B. Zähler entsprechend dem deutschen Mess- und Eichrecht - ME-Zähler) zu erbringen ist und nachvollziehbare Aufzeichnungen von Einzelladevorgängen (automatisierte, produktseitige und nicht manipulierbare Aufzeichnungen der Ladestation), welche die an elektrisch betriebene Kraftfahrzeuge abgegebene energetische Menge in kWh dokumentieren, nicht vorhanden sind.</small>
            </b-form-checkbox>
            -->
            <b-form-radio-group
              stacked
              @change="processFile"
            >
              <b-form-radio
                v-model="newCarData.flatRate"
                name="flatrate"
                :value="true"
              >
                <small>Ich bestätige hiermit, dass <b>eine nachweisliche Messung nicht möglich</b> ist, da der Nachweis einer infrastrukturseitigen Messung auf Ladepunktebene mittels MID konformer Stromzähler (EU-Messgeräterichtlinie, Measurement Instruments Directive, RL 2014/32/EU) oder gleichwertigen bzw. besseren Zählers (z.B. Zähler entsprechend dem deutschen Mess- und Eichrecht - ME-Zähler) zu erbringen ist und nachvollziehbare Aufzeichnungen von Einzelladevorgängen (automatisierte, produktseitige und nicht manipulierbare Aufzeichnungen der Ladestation), welche die an elektrisch betriebene Kraftfahrzeuge abgegebene energetische Menge in kWh dokumentieren, nicht vorhanden sind.</small>
              </b-form-radio>
              <br>
              <b-form-radio
                v-model="newCarData.flatRate"
                name="flatrate"
                :value="false"
              >
                <small><b>Ich messe</b> die Strommengen, die in mein Fahrzeug geladen werden <b>in der gesetzlich geforderten Form</b> und kann eine entsprechende Dokumentation zur Verfügung stellen.</small>
              </b-form-radio>
            </b-form-radio-group>

            <br>
            <b-form-group
              v-show="newCarData.flatRate==false"
            >
              <label
                for="powerUsed"
                class="d-block"
              >
                Geladene Strommenge [kWh]*
                <feather-icon
                  id="powerUsed-tooltip"
                  icon="InfoIcon"
                  size="15"
                />
              </label>
              <validation-provider
                #default="{ errors }"
                name="Geladene Strommenge [kWh]"
                rules="required|positive"
              >
                <cleave
                  id="powerUsed"
                  v-model="newCarData.powerUsed"
                  placeholder="1.500,00"
                  :options="options.number"
                  class="form-control"
                  trim
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-tooltip
              target="powerUsed-tooltip"
              title="Wenn am Ladepunkt gemessen und nachvollziehbar überprüfbar aufgezeichnet wird, muss gemäß KVO §11 die jährliche Strommenge (in kWh) angegeben werden und mit Jahresende die Angabe auf den gemessenen Wert korrigiert werden. Es kann sein, dass sich dadurch auch die Höhe des Bonus ändert."
            />
            <b-form-group
              v-show="newCarData.flatRate==false"
            >
              <label
                for="chargingStation"
                class="d-block"
              >
                Seriennummer Ladestelle*
                <feather-icon
                  id="chargingStation-tooltip"
                  icon="InfoIcon"
                  size="15"
                />
              </label>
              <validation-provider
                v-if="newCarData.flatRate==false"
                #default="{ errors }"
                name="Seriennummer Ladestelle"
                rules="required|alpha-num"
              >
                <b-form-input
                  id="chargingStation"
                  v-model="newCarData.chargingStationId"
                  trim
                  placeholder=""
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-tooltip
              target="chargingStation-tooltip"
              title="Die Seriennummer finden Sie zB auf dem Typenschild der Ladestation. Nur eine Seriennummer angeben. Wenn Sie mehrere Fahrzeuge an mehreren nicht-öffentlich zugänglichen Ladestellen laden, kontaktieren Sie uns bitte."
            />
            <b-form-group
              v-if="newCarData.flatRate==false"
            >
              <label
                for="chargingStationManufacturer"
                class="d-block"
              >
                Ladestelle Hersteller*
              </label>

              <validation-provider
                #default="{ errors }"
                name="Ladestelle Hersteller"
                rules="required|alpha-num"
              >
                <b-form-input
                  id="chargingStationManufacturer"
                  v-model="newCarData.chargingStationManufacturer"
                  trim
                  placeholder=""
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="newCarData.flatRate==false"
            >
              <label
                for="chargingStationPorts"
                class="d-block"
              >
                Anzahl der Ladepunkte*
              </label>

              <validation-provider
                #default="{ errors }"
                name="Anzahl der Ladepunkte"
                rules="required|integer"
              >
                <b-form-input
                  id="chargingStationPorts"
                  v-model="newCarData.chargingStationPorts"
                  trim
                  placeholder="1"
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              v-if="newCarData.flatRate==false"
            >
              <label
                for="chargingStationPowerAdd"
                class="d-block"
              >
                Ladeleistung [kW]*
              </label>

              <validation-provider
                #default="{ errors }"
                name="Ladeleistung"
                rules="required|positive"
              >
                <cleave
                  id="chargingStationPowerAdd"
                  v-model="newCarData.chargingStationPower"
                  placeholder=""
                  trim
                  :options="options.number"
                  class="form-control"
                />
                <small class="text-warning">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <hr v-if="newCarData.flatRate==false">
            <h6 v-if="newCarData.flatRate==false">
              ACHTUNG Sie können nur nachvollziehbare Strommengen dokumentieren wenn Sie Folgendes bereitstellen können:
            </h6>
            <b-form-group
              v-if="newCarData.flatRate==false"
              label="MID Konformität*"
              label-for="MID"
            >
              <small style="color: #5e5873;">
                ein Foto, dass das Typenschild der Ladestation zeigt, auf dem die Seriennummer und die MID Kennzeichnung gut lesbar sind. (keine Subzähler für die Ladestation!)
              </small>
              <file-pond
                ref="pondC"
                name="fileUpload"
                label-idle="Wählen Sie eine Datei oder ziehen Sie es ins Feld. Unterstützte Dateiformate: JPG, PNG - Max Größe: 20MB"
                allow-replace="true"
                allow-revert="true"
                accepted-file-types="image/jpeg, image/png"
                :server="{
                  url: apiUrl,
                  process: {
                    url: `/file-repository/upload/${tempFormUUID}/mid/${authUser.id}`,
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    },
                  },
                  revert: {
                    url: `/file-repository/revert/${tempFormUUID}/mid/${authUser.id}`,
                    method: 'DELETE',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    },
                  },
                }"
                allow-file-encode="true"
                allow-file-rename="true"
                allow-image-resize="true"
                image-resize-target-width="1280"
                image-resize-target-height="720"
                image-resize-mode="cover"
                :file-rename-function="fileRenameFunction"
                style="margin-top: 8px"
                @processfile="processFile"
                @removefile="removeFile"
              />
            </b-form-group>
            <br v-if="newCarData.flatRate==false">
            <b-form-group
              v-if="newCarData.flatRate==false"
              label="Installationsnachweis*"
              label-for="herstellerbestätigungInstallation"
            >
              <small style="color: #5e5873;">
                einen Installationsnachweis (zB eine Förderabrechnung, eine Abnahmebestätigung) mit Verweis auf die Seriennummer der Ladestation
              </small>
              <file-pond
                ref="pondD"
                name="fileUpload"
                label-idle="Wählen Sie eine Datei oder ziehen Sie es ins Feld. Unterstützte Dateiformate: JPG, PNG - Max Größe: 20MB"
                allow-replace="true"
                allow-revert="true"
                accepted-file-types="image/jpeg, image/png"
                :server="{
                  url: apiUrl,
                  process: {
                    url: `/file-repository/upload/${tempFormUUID}/instalation_verification/${authUser.id}`,
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    },
                  },
                  revert: {
                    url: `/file-repository/revert/${tempFormUUID}/instalation_verification/${authUser.id}`,
                    method: 'DELETE',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    },
                  },
                }"
                allow-file-encode="true"
                allow-file-rename="true"
                allow-image-resize="true"
                image-resize-target-width="1280"
                image-resize-target-height="720"
                image-resize-mode="cover"
                :file-rename-function="fileRenameFunction"
                style="margin-top: 8px"
                @processfile="processFile"
                @removefile="removeFile"
              />
            </b-form-group>
            <br v-if="newCarData.flatRate==false">
            <b-form-group
              v-if="newCarData.flatRate==false"
              label="Nachweise für die Einzelladevorgänge*"
              label-for="Nachweise für die Einzelladevorgänge"
            >
              <small style="color: #5e5873;">
                Eine Dokumentation zu Einzelladevorgängen mit Datum, Zeitraum und kWh, die Sie aus der Ladestation auslesen (keine Fahrzeug Apps!). Laden Sie mit dem Antrag ein Beispiel hoch, nach Ablauf des Jahres können Sie dann die Einzelladevorgänge des ganzen Jahres hochladen.
              </small>
              <file-pond
                ref="pondE"
                name="fileUpload"
                label-idle="Wählen Sie eine Datei oder ziehen Sie es ins Feld. Unterstützte Dateiformate: JPG, PNG - Max Größe: 20MB"
                allow-replace="true"
                allow-revert="true"
                accepted-file-types="image/jpeg, image/png"
                :server="{
                  url: apiUrl,
                  process: {
                    url: `/file-repository/upload/${tempFormUUID}/evidence_individual_charging_processes/${authUser.id}`,
                    method: 'POST',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    },
                  },
                  revert: {
                    url: `/file-repository/revert/${tempFormUUID}/evidence_individual_charging_processes/${authUser.id}`,
                    method: 'DELETE',
                    headers: {
                      'Authorization': `Bearer ${accessToken}`
                    },
                  },
                }"
                allow-file-encode="true"
                allow-file-rename="true"
                allow-image-resize="true"
                image-resize-target-width="1280"
                image-resize-target-height="720"
                image-resize-mode="cover"
                :file-rename-function="fileRenameFunction"
                style="margin-top: 8px"
                @processfile="processFile"
                @removefile="removeFile"
              />
            </b-form-group>
            <br>
            <hr>
            <!-- Form Actions -->
            <b-alert
              :variant="partnerData.colorScheme"
              :show="!canAddNewCar"
            >
              <div class="alert-body">
                <span style="font-size:13px;">Bitte vervollständigen Sie Ihre Daten bevor Sie den Bonus beantragen können.</span>
              </div>
            </b-alert>
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="partnerData.colorScheme"
                :disabled="!canAddNewCar"
                class="mr-2"
                type="submit"
              >
                Beantragen
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="resetCarData();hide();"
              >
                Abbrechen
              </b-button>
            </div>

          </b-form>
        </validation-observer>
        <b-modal
          id="new-car-error-modal"
          ref="newCarErrorModal"
          centered
          title="Fehler"
          ok-only
          ok-title="OK"
          :ok-variant="partnerData.colorScheme"
        >
          <b-card-text>
            Eintrag mit FIN und ausgewählten Datum existiert bereits.
          </b-card-text>
        </b-modal>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormSelect, BFormDatepicker, BTooltip, BModal, VBModal, BAlert, BFormCheckbox, BFormRadio, BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref,
  onUnmounted,
  watch,
  computed,
} from '@vue/composition-api'
import {
  required, alphaNum, email, compareDates,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { v4 as uuidv4 } from 'uuid'

import vueFilePond from 'vue-filepond'
//  Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode'
import FilePondPluginFileRename from 'filepond-plugin-file-rename'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'

import Cleave from 'vue-cleave-component'
import { numberFormat } from '@core/utils/localSettings'

import { $authUrl } from '@microServicesConfig'

import store from '@/store'
import sidebarStoreModel from './sidebarStoreModule'

//  Import filepond styles
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    BFormDatepicker,
    BTooltip,
    BFormCheckbox,
    BFormRadio,
    BFormRadioGroup,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BModal,
    BAlert,
    Cleave,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: 'isAddNewCarActive',
    event: 'update:is-add-new-car-active',
  },
  props: {
    isAddNewCarActive: {
      type: Boolean,
      required: true,
    },
    tempCarVin: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      compareDates,
      options: {
        number: numberFormat,
      },
      useTypes: [
        { value: '1', text: 'Privatfahrzeug' },
        { value: '2', text: 'Firmenwagen' },
      ],
      connectorTypes: [
        { value: '1', text: 'Typ 1' },
        { value: '2', text: 'Typ 2' },
        { value: '3', text: 'CCS' },
        { value: '4', text: 'CHAdeMO' },
      ],
      apiUrl: $authUrl,
    }
  },
  methods: {
    fileRenameFunction(file) {
      return `${uuidv4()}${file.extension}`
    },
  },
  setup(props, { emit, refs }) {
    const APP_SIDEBAR_STORE_MODULE_NAME = 'app-sidebar'

    // Register module
    if (!store.hasModule(APP_SIDEBAR_STORE_MODULE_NAME)) store.registerModule(APP_SIDEBAR_STORE_MODULE_NAME, sidebarStoreModel)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_SIDEBAR_STORE_MODULE_NAME)) store.unregisterModule(APP_SIDEBAR_STORE_MODULE_NAME)
    })

    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileEncode, FilePondPluginFileRename, FilePondPluginImageResize, FilePondPluginImageTransform)

    const authUser = window.$cookies.get('userData')
    const accessToken = window.$cookies.get('accessToken')

    /*
    let currentYear = new Date().getFullYear().toString()
    // . it is nice to make exceptions
    if (currentYear === '2022') {
      currentYear = '2023'
    }

    currentYear = '2023'
    */

    const partner = ref(window.$cookies.get('partner'))
    const partnerData = ref(window.$cookies.get('partnerData'))
    const getHrefStyle = () => `color:var(--${partnerData.value.colorScheme})`

    const currentYear = partnerData.value.activeYear
    const dateFrom = `${currentYear}-01-01`
    const dateTo = `${currentYear}-12-31`

    const minDate = new Date(`${currentYear}-01-01`)
    const maxDate = new Date(`${currentYear}-12-31`)

    const isMobile = ref(true)
    const currentBreakPointVal = ref(store.getters['app/currentBreakPoint'])
    const sidebarMinWidth = ref('95%')
    const bodyPadding = ref('0')
    if (store.getters['app/currentBreakPoint'] === 'xl' || store.getters['app/currentBreakPoint'] === 'lg') {
      isMobile.value = false
      sidebarMinWidth.value = '700px'
      bodyPadding.value = '50'
    }

    const currentBreakPoint = computed(() => store.getters['app/currentBreakPoint'])
    watch(currentBreakPoint, val => {
      currentBreakPointVal.value = val
      if (val === 'xl' || val === 'lg') {
        isMobile.value = false
        sidebarMinWidth.value = '700px'
        bodyPadding.value = '50'
      } else {
        isMobile.value = true
        sidebarMinWidth.value = '95%'
        bodyPadding.value = '0'
      }
    })

    // . console.log(props)

    const blankCarData = {
      carTitle: '',
      vin: '',
      useType: 1,
      address: null,
      extraCharhingInfo: '',
      connectorType: 2,
      powerUsed: 0,
      dateFrom,
      dateTo,
      flatRate: null,
      chargingStationId: '',
      chargingStationPorts: null,
      chargingStationPower: null,
      chargingStationManufacturer: '',
      partner: '',
    }

    const tempFormUUID = ref(uuidv4())
    const canAddNewCar = ref(false)

    const newCarData = ref(JSON.parse(JSON.stringify(blankCarData)))
    newCarData.value.vin = props.tempCarVin
    const resetCarData = () => {
      newCarData.value = JSON.parse(JSON.stringify(blankCarData))
      newCarData.value.vin = props.tempCarVin
      tempFormUUID.value = uuidv4()

      refs.pondA.removeFiles()
      refs.pondB.removeFiles()

      if (refs.pondC) {
        refs.pondC.removeFiles()
      }

      if (refs.pondD) {
        refs.pondD.removeFiles()
      }

      if (refs.pondE) {
        refs.pondE.removeFiles()
      }
    }

    const addressList = ref([])
    addressList.value.push({ value: null, text: 'Bitte auswählen' })
    addressList.value.push({ value: '-1', text: 'neue Adresse' })

    store.dispatch(`${APP_SIDEBAR_STORE_MODULE_NAME}/getAddressList`, { id: authUser.id })
      .then(response => {
        for (let i = 0; i < response.data.length; i += 1) {
          const tmpObj = {
            value: response.data[i].id,
            text: `${response.data[i].street}, ${response.data[i].zip} ${response.data[i].city}`,
          }

          addressList.value.push(tmpObj)
        }
      })

    const onSubmit = () => {
      newCarData.value.tempId = tempFormUUID.value
      newCarData.value.partner = window.$cookies.get('partner')
      store.dispatch(`${APP_SIDEBAR_STORE_MODULE_NAME}/add`, { id: authUser.id, newCarData: newCarData.value })
        .then(response => {
          if (response.status === 200) {
            emit('update:is-add-new-car-active', false)
            store.commit('app-home/updateCarAddedAt')
          } else {
            refs.newCarErrorModal.show()
          }
        })
        .catch(() => {
          refs.newCarErrorModal.show()
        })
    }

    watch(() => props.tempCarVin, (oldVal, newVal) => {
      if (oldVal !== newVal) {
        newCarData.value.vin = props.tempCarVin
      }
    })

    const processFile = () => {
      canAddNewCar.value = false

      if (newCarData.value.flatRate == null) {
        return
      }

      if (newCarData.value.flatRate === true) {
        if ((refs.pondA.getFiles().length + refs.pondB.getFiles().length) === 2) {
          canAddNewCar.value = true
        }
      } else if ((refs.pondA.getFiles().length + refs.pondB.getFiles().length + refs.pondC.getFiles().length + refs.pondD.getFiles().length + refs.pondE.getFiles().length) === 5) {
        canAddNewCar.value = true
      }
    }

    const removeFile = () => {
      canAddNewCar.value = false
    }

    return {
      newCarData,
      onSubmit,
      resetCarData,
      processFile,
      removeFile,

      FilePond,
      addressList,
      tempFormUUID,
      accessToken,
      authUser,

      partner,
      partnerData,
      getHrefStyle,
      isMobile,
      currentBreakPointVal,
      sidebarMinWidth,
      bodyPadding,

      canAddNewCar,
      minDate,
      maxDate,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-car-active {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

/*
.b-sidebar.sidebar-lg {
    min-width: 700px;
}
*/
</style>
