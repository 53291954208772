import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    add(ctx, {
      id, newCarData,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/car/add-web/${id}`, newCarData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAddressList(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/address/get-user-address-list/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getCarDetail(ctx, { userId, carId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/car/get-car-detail/${userId}/${carId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFile(ctx, { fileId, userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/file-repository/get-file/${fileId}/${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    deleteImage(ctx, { fileId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/file-repository/delete-file/${fileId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    confirmData(ctx, { bonusId, userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/car/confirm-status/${bonusId}/${userId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, {
      id, bonusId, carData,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/car/edit/${id}/${bonusId}`, carData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    clone(ctx, {
      id, bonusId, carData,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/car/clone-web/${id}/${bonusId}`, carData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
