const bonusStatus = {
  10: 'Beantragt',
  11: 'Datenprüfung',
  15: 'Daten inkorrekt', //  intern
  20: 'Datenbestätigung ausstehend',
  21: 'Datenbestätigung Erinnerung',
  //  25: 'Daten inkorrekt',
  30: 'Daten bestätigt',
  40: 'Daten nicht bestätigt',
  50: 'Kündigung',
  60: 'Antrag positiv',
  70: 'Antrag negativ',
  80: 'Bonus ausgefolgt',
}

const connectorTypes = [
  { value: 1, text: 'Typ 1' },
  { value: 2, text: 'Typ 2' },
  { value: 3, text: 'CCS' },
  { value: 4, text: 'CHAdeMO' },
]

module.exports = {
  connectorTypes,
  bonusStatus,
}
