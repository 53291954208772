<template>
  <b-sidebar
    id="add-new-car-charging-data-active"
    :visible="isAddNewCarChargingDataActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-car-charging-data-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Neues Berichtsjahr
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <h6>Daten</h6>
          <hr>
          <!--
          <b-form-group
            label="Anrede"
            label-for="gender"
          >
            <b-form-select
              v-model="userData.gender"
              :options="gender_options"
            />
          </b-form-group>
          -->

          <b-form-group
            label="Geladene Strommenge [kWh]*"
            label-for="titlePrefix"
          >
            <b-form-input
              id="titlePrefix"
              v-model="userData.titlePrefix"
              autofocus
              trim
              placeholder=""
            />
          </b-form-group>
          <!-- First Name -->
          <validation-provider
            #default="validationContext"
            name="Startzeitpunkt"
            rules="required"
          >
            <b-form-group
              label="Startzeitpunkt"
              label-for="firstName"
            >
              <b-form-datepicker
                locale="de"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Last Name -->
          <validation-provider
            #default="validationContext"
            name="Endzeitpunkt"
            rules="required"
          >
            <b-form-group
              label="Endzeitpunkt"
              label-for="lastName"
            >
              <b-form-datepicker
                locale="de"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Hinzufügen
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Abbrechen
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormDatepicker,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

localize('de')
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormDatepicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewCarChargingDataActive',
    event: 'update:is-add-new-car-charging-data-active',
  },
  props: {
    isAddNewCarChargingDataActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      gender_options: [
        { value: null, text: 'Bitte auswählen' },
        { value: '1', text: 'Volvo V70' },
        { value: '2', text: 'Lexus GS' },
        { value: '3', text: 'Toyota Prius' },
      ],
    }
  },
  setup(props, { emit }) {
    const toast = useToast()

    const blankUserData = {
      firstName: '',
      lastName: '',
      email: '',
      phoneNr: '',
      gender: '',
      titlePrefix: '',
      titleSuffix: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(response => {
          if (response.data.status === 200) {
            toast({
              component: ToastificationContent,
              props: {
                title: 'New user added successfully',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            emit('refetch-data')
            emit('update:is-add-new-car-charging-data-active', false)
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error adding new user -- user exists',
                text: response.data.payload.message,
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            })
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Reuest Failed',
              icon: 'CoffeeIcon',
              variant: 'warning',
              text: 'Error connecting to server',
            },
          })
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-car-charging-data-active {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
