/*
Some local definitions
*/
export const localization = 'de'
export const numberFormat = {
  numeral: true,
  numeralDecimalMark: ',',
  delimiter: '.',
}
export const numberFormatDeep = {
  numeral: true,
  numeralDecimalMark: ',',
  delimiter: '.',
  numeralDecimalScale: 4,
}
export const numberFormatSmall = {
  numeral: true,
  numeralDecimalMark: ',',
  delimiter: '.',
}
